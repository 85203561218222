import { graphql, useStaticQuery } from "gatsby"

const TALKS_QUERY = graphql`
  query {
    result: allMdx(
      filter: { fileAbsolutePath: { regex: "/talks/i" } }
      sort: { fields: frontmatter___position, order: DESC }
    ) {
      talks: nodes {
        talk: frontmatter {
          title
          shortInfo
          path
          videoLength
          place
          date
          thumbnail {
            childImageSharp {
              fluid(
                maxWidth: 600
                maxHeight: 500
                fit: CONTAIN
                quality: 100
                cropFocus: EAST
                background: "rgb(0,0,0,0)"
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const useTalks = () => {
  const {
    result: { talks },
  } = useStaticQuery(TALKS_QUERY)
  return talks
}

export default useTalks
