import React from "react"
import Text from "components/common/Text"
import Space from "components/common/Space"
import styled from "styled-components"
import useTalks from "../../hooks/useTalks"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Avatar from "components/talks/Avatar"
import { motion } from "framer-motion"
import { Event, track } from "../../analytics"
import SEO from "components/layout/SEO"

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`

const TalkItem = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  cursor: pointer;

  @media only screen and (max-width: 9999px) {
    transition: transform 500ms;
    &:hover {
      .talk-image {
        transition: box-shadow 500ms ease-in-out;
        box-shadow: ${props => `0px 0px 40px -10px rgba(0,0,0,0.4)`};
      }
      div {
        transition: color 500ms, box-shadow 500ms ease-in-out;
        color: ${props => props.theme.colors.accent.primary[500]};
      }
      transform: scale(1.05);
    }
  }
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
    transition: background-color 200ms;
    margin-bottom: 2rem;
    &:hover {
      div {
        transition: color 500ms;
        color: ${props => props.theme.colors.text.primary[500]};
      }
      .talk-image {
        box-shadow: none;
      }
      transform: none;
      background-color: ${props => props.theme.colors.accent.primary[50]};
    }
  }
`

const TalkImage = styled(Img)`
  width: 40%;
  min-width: 400px;
  height: 250px;
  box-shadow: ${props => `0px 0px 40px 2px rgba(0,0,0,0.4)`};
  transition: box-shadow 500ms ease-in-out;
  margin-right: 2rem;
  border-radius: 4px;
  @media only screen and (max-width: 600px) {
    box-shadow: 0px 0px 0px 0px;
    margin-right: 0px;
    min-width: 200px;
    width: 100%;
  }
  @media only screen and (max-width: 830px) {
    margin-right: 0px;
  }
`

const TalkInfo = styled.div`
  min-width: 300px;
  width: 500px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  @media only screen and (max-width: 960px) {
    margin-top: 1rem;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    min-width: 0px;
    margin-top: 1rem;
    padding: 0px 8px;
    padding-bottom: 8px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    min-width: 0px;
    margin-top: -0.5rem;
    padding: 0px 8px;
    padding-bottom: 8px;
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
`

const Talks = () => {
  const talks = useTalks()
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <SEO title="Talks - Chaitanya Deorukhkar" />
      <Avatar />
      <Space margin="2rem 0 0 0">
        <div>
          {talks.map(({ talk }, index) => (
            <NavLink
              to={`/${talk.path}`}
              key={index}
              onClick={() =>
                track(Event.talk_item_clicked, { title: talk.title })
              }
            >
              <TalkItem>
                <TalkImage
                  fluid={talk.thumbnail.childImageSharp.fluid}
                  alt={`${talk.title}-thumbnail`}
                  fadeIn
                  className="talk-image"
                />
                <TalkInfo>
                  <Text size="large">{talk.title}</Text>
                  <Space padding="1rem 0px 0px 0px">
                    <Text size="medium" weight="light">
                      {talk.shortInfo}
                    </Text>
                  </Space>
                  <Space padding="2rem 0px 0px 0px">
                    <Text size="xsmall" weight="normal">
                      {talk.place} · {talk.videoLength} · {talk.date}
                    </Text>
                  </Space>
                </TalkInfo>
              </TalkItem>
            </NavLink>
          ))}
        </div>
      </Space>
    </Container>
  )
}

export default Talks
